@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

html {
  font-family: 'Outfit', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}


@keyframes fly {
  0% {
    transform: translateY(-10%);
  }
  50% {
    transform: translateY(10%);
  }
  100% {
    transform: translateY(-10%);
  }
}

.animate-fly {
  animation: fly 12s ease-in-out infinite;
}

.swiper-pagination {
  margin-top: 16px !important;
}

.swiper-pagination-bullet-active {
  background-color: #7A66E9 !important;
}